html,
body,
#root {
  height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

main {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 50px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.center {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

#navbar-user {
  margin-right: 20px;
  padding-right: 10px;
  padding-left: 10px;
  color: #ffffff;
}

.signin-container {
  padding-top: 8%;
}

.form-signin {
  text-align: center;
  width: 100%;
  max-width: 400px;
  margin: auto;
}

.quotes-container,
.signin-container,
#user-container {
  width: 100%;
}

/* Class used to change the color of an svg, computed with https://codepen.io/sosuke/pen/Pjoqqp */
.filter-grey {
  filter: invert(76%) sepia(0%) saturate(9%) hue-rotate(149deg) brightness(90%)
    contrast(90%);
}

footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 2;
  text-align: center;
  opacity: 0.7;
  background-color: #444444;
}

footer a {
  color: #ffffff;
}

#copyright {
  margin: 0.5rem;
}

/* Farbliche Anpassungen */
.btn-primary {
  background-color: #8d915d;
  border-color: #8d915d;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary.active,
.open > .dropdown-toggle.btn-primary {
  color: #fff;
  background-color: #4f5513;
  border-color: #4f5513;
}

.btn-outline-primary {
  color: #8d915d;
  border-color: #8d915d;
}

.btn-outline-primary:hover,
.btn-outline-primary:focus,
.btn-outline-primary:active,
.btn-outline-primary.active,
.open > .dropdown-toggle.btn-outline-primary {
  color: #fff;
  background-color: #4f5513;
  border-color: #4f5513;
}

.page-item.active .page-link {
  background-color: #8d915d;
  border-color: #8d915d;
}

.page-link {
  color: #8d915d;
  cursor: pointer;
}

a {
  color: #8d915d;
}

.btn-primary.disabled,
.btn-primary:disabled {
  background-color: #e2d8a6;
  border-color: #8d915d;
}

.jumbotron {
  background-color: #e4e0cc;
}
